import React from "react"
import * as s from "./Content.module.css"

const Content = ({children}) => (
  <div className={s.siteContent}>
    <main className={s.siteContent__main}>
      {children}
    </main>
  </div>
)

export default Content
