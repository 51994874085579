import React from "react"
import { Helmet } from 'react-helmet'
import { buildPageTitle } from '../utils/pageTitle'
import { graphql, useStaticQuery } from 'gatsby'

import Content from '../components/common/Content'
import Header from "../components/common/Header"
import NavMenu from '../components/common/NavMenu'

const DefaultLayout = ({ pageTitle = null, useShortTitle = true, children }) => {

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `)

  return (
    <div>
      <Helmet title={buildPageTitle(site, pageTitle, { useShortTitle })}>
        <meta charset="utf-8" />
      </Helmet>

      <Header />
      <NavMenu />

      <Content>
        {children}
      </Content>
    </div>
  )
}

export default DefaultLayout
