import React from "react"
import { Link } from "gatsby"
import * as s from "./NavMenu.module.css"

const TODO_itemLink = '/'

const MenuItem = ({ label }) => (
  <Link to={TODO_itemLink} className={s.navItemLink}>
    <li className={s.navItem}>{label}</li>
  </Link>
)

const NavMenu = () => (
  <nav className={s.navMenu}>
    <ul className={s.navMenuList}>
      <MenuItem label="Blog" />
      <MenuItem label="Articles" />
      <MenuItem label="About me" />
    </ul>
  </nav>
)

export default NavMenu
