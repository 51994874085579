import React from "react"
import { Link } from "gatsby"
import * as s from "./BlogPostMeta.module.css"

function joinElements(elements, glue) {
  return elements.reduce(
    (acc, el) => acc === null ? [el] : [...acc, glue, el],
    null
  )
}

const BlogPostMeta = ({ date, categories, tags }) => {
  const isoDate = date.toISOString(/* keepOffset: */ true)
  const readableDate = date.format('DD MMMM Y')

  return <footer className={s.blogPostMeta}>
    <div className={s.blogPostMeta__taxonomy}>
      <div className={s.blogPostMeta__category}>
        <span className={s.blogPostMeta__categoryLabel}>Categoria</span>
        {joinElements(categories.map(category =>
          <span className={s.blogPostMeta__categoryValue} key={category.id}>
            <Link to={category.url}>{category.name}</Link>
          </span>
        ), ', ')}
      </div>
      <div className={s.blogPostMeta__tags}>
        <span className={s.blogPostMeta__tagsLabel}>Tags</span>
        <div className={s.blogPostMeta__tagList}>
          {tags.map(tag =>
            <span className={s.blogPostMeta__tagValue} key={tag.id}>
              <Link to={tag.url}>{tag.name}</Link>
            </span>
          )}
        </div>
      </div>

    </div>
    <time className={s.blogPostMeta__timePublished} dateTime={isoDate} title={isoDate}>
      {readableDate}
    </time>
  </footer>
}

export default BlogPostMeta
