// extracted by mini-css-extract-plugin
export const blogPostMeta = "BlogPostMeta-module--blogPostMeta--1ww5-";
export const blogPostMeta__timePublished = "BlogPostMeta-module--blogPostMeta__timePublished--37oXJ";
export const blogPostMeta__taxonomy = "BlogPostMeta-module--blogPostMeta__taxonomy--BMm4K";
export const blogPostMeta__tags = "BlogPostMeta-module--blogPostMeta__tags--3Bojn";
export const blogPostMeta__categoryLabel = "BlogPostMeta-module--blogPostMeta__categoryLabel--aRV-l";
export const blogPostMeta__tagsLabel = "BlogPostMeta-module--blogPostMeta__tagsLabel--agwG3";
export const blogPostMeta__categoryValue = "BlogPostMeta-module--blogPostMeta__categoryValue--Sx1Fm";
export const blogPostMeta__category = "BlogPostMeta-module--blogPostMeta__category--3409e";
export const blogPostMeta__tagList = "BlogPostMeta-module--blogPostMeta__tagList--rQuGi";
export const blogPostMeta__tagValue = "BlogPostMeta-module--blogPostMeta__tagValue--1isGq";