import React from "react"
import { Link } from "gatsby"
import * as s from "./Header.module.css"

const Header = () => (
  <header className={s.siteHeader}>
    <Link to="/" title="Ir para a página inicial" className={s.siteHeaderLink}>
      <h1 className={s.siteHeader__siteTitle}>Eduardo Dobay</h1>
    </Link>
  </header>
)

export default Header
