import React from "react"
import { Link } from "gatsby"
import * as s from "./BlogPostTitle.module.css"

const BlogPostTitle = ({ title, path }) => {
  const linkTitle = `Permalink to ${title}`

  return <header className={s.blogPostTitle}>
    <h2 className={s.blogPostTitle__text}>
      <Link
        to={path}
        rel="bookmark"
        title={linkTitle}>
        {title}
      </Link>
    </h2>
  </header>
}

export default BlogPostTitle
